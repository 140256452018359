var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.themeObject ? _c('v-form', {
    ref: "form",
    staticClass: "mb-5",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('validate');
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('id'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.uniqueTheme],
      "required": "",
      "disabled": _vm.edit
    },
    model: {
      value: _vm.themeObject._id,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "_id", $$v);
      },
      expression: "themeObject._id"
    }
  }), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('name'),
      "outlined": "",
      "rules": [_vm.rules.required],
      "required": ""
    },
    model: {
      value: _vm.themeObject.name,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "name", $$v);
      },
      expression: "themeObject.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('bcmURL'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.bcmURL,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "bcmURL", $$v);
      },
      expression: "themeObject.bcmURL"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('logo')) + " ")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('logoLogin'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.logoLogin,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "logoLogin", $$v);
      },
      expression: "themeObject.logoLogin"
    }
  })], 1), _c('v-col', [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "color": "menuBackground"
    }
  }, [_c('v-img', {
    attrs: {
      "lazy-src": _vm.themeObject.logoLogin,
      "max-height": "64",
      "max-width": "64",
      "src": _vm.themeObject.logoLogin
    }
  })], 1)], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('logoFull'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.logoFull,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "logoFull", $$v);
      },
      expression: "themeObject.logoFull"
    }
  })], 1), _c('v-col', [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "color": "menuBackground"
    }
  }, [_c('v-img', {
    attrs: {
      "lazy-src": _vm.themeObject.logoFull,
      "max-height": "85",
      "max-width": "300",
      "contain": "",
      "src": _vm.themeObject.logoFull
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('logoSmall'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.logoSmall,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "logoSmall", $$v);
      },
      expression: "themeObject.logoSmall"
    }
  })], 1), _c('v-col', [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "color": "menuBackground"
    }
  }, [_c('v-img', {
    attrs: {
      "lazy-src": _vm.themeObject.logoSmall,
      "height": "90",
      "width": "300",
      "contain": "",
      "src": _vm.themeObject.logoSmall
    }
  })], 1)], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('favicon'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.favicon,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "favicon", $$v);
      },
      expression: "themeObject.favicon"
    }
  })], 1), _c('v-col', [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "color": "menuBackground"
    }
  }, [_c('v-img', {
    attrs: {
      "lazy-src": _vm.themeObject.favicon,
      "max-height": "30",
      "max-width": "30",
      "src": _vm.themeObject.favicon
    }
  })], 1)], 1)], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('documents')) + " ")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('cgv'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.url],
      "required": ""
    },
    model: {
      value: _vm.themeObject.cgv,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "cgv", $$v);
      },
      expression: "themeObject.cgv"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('support')) + " ")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('supportEmail'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.email],
      "required": ""
    },
    model: {
      value: _vm.themeObject.supportEmail,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "supportEmail", $$v);
      },
      expression: "themeObject.supportEmail"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('registration')) + " ")]), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableRegistration')
    },
    model: {
      value: _vm.themeObject.enableRegistration,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "enableRegistration", $$v);
      },
      expression: "themeObject.enableRegistration"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.offers,
      "item-text": `title.${_vm.$userLocale}`,
      "item-value": "_id",
      "label": _vm.$t('registrationOffer'),
      "outlined": "",
      "rules": [_vm.rules.required],
      "required": ""
    },
    model: {
      value: _vm.themeObject.registrationOffer,
      callback: function ($$v) {
        _vm.$set(_vm.themeObject, "registrationOffer", $$v);
      },
      expression: "themeObject.registrationOffer"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('scripts')) + " ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }