<template lang="html" src="./pageThemes.template.vue"></template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import Theme from '../../components/theme/Theme';

const i18nData = require('./pageThemes.i18n');

export default {
  name: 'PageThemes',
  components: { Theme },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Dialog new theme template
       */
      dialogAddNewThemeTemplate: false,
      /**
       * Dialog edit theme
       */
      dialogEditThemeTemplate: [],
      /**
       * Dialog confirm delete theme
       */
      dialogConfirmDeleteTheme: [],
      /**
       * Datatable themes
       */
      headers: [],
      themes: [],
      search: '',
      loading: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('theme'),
        value: '_id',
      },
      {
        text: this.$t('name'),
        value: 'name',
      },
      {
        text: this.$t('enableRegistration'),
        value: 'enableRegistration',
      },
      {
        text: this.$t('registrationOffer'),
        value: 'registrationOffer',
        align: 'left',
      },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    init() {
      this.themes = [];
      this.loading = true;
      this.getThemes();
    },
    getThemes() {
      this.$apiInstance.getThemes()
        .then((data) => {
          this.themes = data;
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      const theme = this.$refs.formTheme.save();
      if(!theme) {
        this.isValid = false;
      }else{
        /**
         * Save theme
         */
        this.$apiInstance.addTheme(theme)
          .then((data)=>{
            this.dialogAddNewThemeTemplate = false;
            this.$notification.notify('SUCCESS',this.$t('themeAdded'));
            this.init();
          })
          .catch((error)=>{
            /**
             * ERROR ADD OFFER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });

        /**
         * Close dialog
         */
        this.dialogAddNewThemeTemplate = false;
      }
    },
    deleteTheme(id) {
      this.$apiInstance.deleteTheme(id)
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('themeDeleted'));
            this.init();
          }, (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });
    },
    saveEditTheme(id) {
      const theme = this.$refs[`formThemeEdit_${id}`].save();
      if(!theme) {
        this.isValid = false;
      }else{
        /**
         * Save theme
         */
        this.$apiInstance.modifyTheme(id, theme)
          .then((data)=>{
            this.dialogConfirmDeleteTheme[id] = false;
            this.$notification.notify('SUCCESS',this.$t('themeModified'));
            this.init();
          })
          .catch((error)=>{
            /**
             * ERROR ADD OFFER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });

        /**
         * Close dialog
         */
        this.dialogEditThemeTemplate[id] = false;
      }
    }
  },
};
</script>
