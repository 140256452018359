<template>
  <v-form
    v-if="themeObject"
    ref="form"
    v-model="valid"
    lazy-validation
    class="mb-5"
    @input="$emit('validate')"
  >
    <!-- ID -->
    <v-text-field
      v-model="themeObject._id"
      :label="$t('id')"
      outlined
      :rules="[rules.required, rules.uniqueTheme]"
      required
      :disabled="edit"
    ></v-text-field>
    <v-row>
      <v-col>
        <!-- NAME -->
        <v-text-field
          v-model="themeObject.name"
          :label="$t('name')"
          outlined
          :rules="[rules.required]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- BCM URL -->
        <v-text-field
          v-model="themeObject.bcmURL"
          :label="$t('bcmURL')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- LOGO -->
    <h3 class="mb-2">
      {{ $t('logo') }}
    </h3>
    <v-row>
      <v-col>
        <!-- Logo Login -->
        <v-text-field
          v-model="themeObject.logoLogin"
          :label="$t('logoLogin')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card color="menuBackground" class="pa-3">
          <v-img
            :lazy-src="themeObject.logoLogin"
            max-height="64"
            max-width="64"
            :src="themeObject.logoLogin"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <!-- Logo Full -->
        <v-text-field
          v-model="themeObject.logoFull"
          :label="$t('logoFull')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card color="menuBackground" class="pa-3">
          <v-img
            :lazy-src="themeObject.logoFull"
            max-height="85"
            max-width="300"
            contain
            :src="themeObject.logoFull"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Logo Small -->
        <v-text-field
          v-model="themeObject.logoSmall"
          :label="$t('logoSmall')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card color="menuBackground" class="pa-3">
          <v-img
            :lazy-src="themeObject.logoSmall"
            height="90"
            width="300"
            contain
            :src="themeObject.logoSmall"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <!-- Favicon -->
        <v-text-field
          v-model="themeObject.favicon"
          :label="$t('favicon')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card color="menuBackground" class="pa-3">
          <v-img
            :lazy-src="themeObject.favicon"
            max-height="30"
            max-width="30"
            :src="themeObject.favicon"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
    <!-- Documents -->
    <h3 class="mb-2">
      {{ $t('documents') }}
    </h3>
    <v-row>
      <v-col>
        <!-- CGV -->
        <v-text-field
          v-model="themeObject.cgv"
          :label="$t('cgv')"
          outlined
          :rules="[rules.required, rules.url]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Support -->
    <h3 class="mb-2">
      {{ $t('support') }}
    </h3>
    <v-row>
      <v-col>
        <!-- SUPPORT EMAIL -->
        <v-text-field
          v-model="themeObject.supportEmail"
          :label="$t('supportEmail')"
          outlined
          :rules="[rules.required, rules.email]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Registration -->
    <h3 class="mb-2">
      {{ $t('registration') }}
    </h3>
    <v-row>
      <v-col>
        <!-- Enable Registration -->
        <v-switch
          v-model="themeObject.enableRegistration"
          :label="$t('enableRegistration')"
        ></v-switch>
      </v-col>
      <v-col>
        <!--  Registration Offer -->
        <v-autocomplete
          v-model="themeObject.registrationOffer"
          :items="offers"
          :item-text="`title.${$userLocale}`"
          item-value="_id"
          :label="$t('registrationOffer')"
          outlined
          :rules="[rules.required]"
          required
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- Scripts -->
    <h3 class="mb-2">
      {{ $t('scripts') }}
    </h3>
  </v-form>
</template>

<script>
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';

const i18nData = require('./theme.i18n');
export default {
  name: 'Theme',
  components: {
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The theme object
     */
    theme: {
      type: Object,
      required: true
    },
    /**
     * Disable some fields if we edit the object
     */
    edit: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    theme: {
      handler: function() {
        this.init();
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      /**
       * Offer Object
       */
      themeObject: null,
      /**
       * All Themes
       */
      themes: [],
      /**
       * All Offers
       */
      offers: [],
      /**
       * Form validation status
       */
      valid: false,
      /**
       * Form validation rules
       */
      rules: {
        required: (value) =>
          (value && value.toString().trim() !== '') || this.$t('requiredField'),
        uniqueTheme: (value) =>
          (value && this.themes.filter(e => { if(e._id.toUpperCase().trim() === value.toUpperCase().trim()) { return true; } return false; }).length === 0) || this.$t('themeAlreadyExists'),
        url: (value) =>
          (value && isUrl(value, {protocols: ['https', 'http']})) || this.$t('invalidUrl'),
        email: (value) =>
          (value && isEmail(value)) || this.$t('invalidEmail'),
      },
    };
  },
  created() {
    
  },
  mounted() {
    /**
     * Reset validation
     */
    this.$refs.form.resetValidation();
  },
  methods: {
    init() {
      const themeObject = JSON.parse(JSON.stringify(this.theme));
      /**
       * Delete virtual property 'environment'
       */
      delete themeObject['environment'];
      /**
       * Default Object
       */
      const defaultObject = {
        _id: '',
        name: '',
        bcmURL: 'https://bcm.cm-technology.io',
        logoLogin: 'https://storage.gra.cloud.ovh.net/v1/AUTH_8b011ee3f5fc45c2a08336396b50129b/bcm-static/default/logo_bcm_circle_301x301.png',
        logoFull: 'https://storage.gra.cloud.ovh.net/v1/AUTH_8b011ee3f5fc45c2a08336396b50129b/bcm-static/default/logo-bcm.png',
        logoSmall: 'https://storage.gra.cloud.ovh.net/v1/AUTH_8b011ee3f5fc45c2a08336396b50129b/bcm-static/default/logo-bcm-small_300x300.png',
        favicon: 'https://storage.gra.cloud.ovh.net/v1/AUTH_8b011ee3f5fc45c2a08336396b50129b/bcm-static/default/favicon.png',
        cgv: 'https://bcm.cm-technology.io/contracts/cgv.pdf',
        supportEmail: 'support@cm-technology.io',
        scriptBeforeLogin: '',
        scriptBeforeLoginIntegrity: '',
        scriptAfterLogin: '',
        scriptAfterLoginIntegrity: '',
        enableRegistration: true,
        registrationOffer: 'BCM'
      };
      this.fillObjectWithDefaultValue(themeObject, defaultObject);
      /**
       * Update
       */
      this.themeObject  = themeObject;
      /**
       * Get all theme
       */
      this.getAllThemes();
      /**
       * Get all offers
       */
      this.getAllOffers();
    },
    fillObjectWithDefaultValue(objectToFill, defaultValuesObject) {
      for (const key of Object.keys(defaultValuesObject)) {
        if(!objectToFill.hasOwnProperty(key)) { // Attribute doesn't exists
          if(defaultValuesObject[key] instanceof Object && !Array.isArray(defaultValuesObject[key])) {
            objectToFill[key] = {};
            this.fillObjectWithDefaultValue(objectToFill[key], defaultValuesObject[key]);
          }else{
            objectToFill[key] = JSON.parse(JSON.stringify(defaultValuesObject[key]));
          }
        }else if(objectToFill[key] instanceof Object && !Array.isArray(objectToFill[key])) { // Attribute exists and is an object, need to verify if sub-attribute exists
          this.fillObjectWithDefaultValue(objectToFill[key], defaultValuesObject[key]);
        }
      }
    },
    getAllThemes() {
      this.$apiInstance.getThemes()
        .then(
          (data) => {
            this.themes = data;
          },
          (error) => {
            /**
             * ERROR GET OFFERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getAllOffers() {
      this.$apiInstance.getAdminWarehouseOffers()
        .then(
          (data) => {
            this.offers = data;
          },
          (error) => {
            /**
             * ERROR GET OFFERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    validate() {
      this.$refs?.form?.validate();
      return this.valid;
    },
    save() {
      if(this.validate()) {
        return this.themeObject;
      }else{
        return null;
      }
    }
  },
};
</script>
