var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('themes')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-5",
    attrs: {
      "color": "info",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.dialogAddNewThemeTemplate = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('addNewTheme')) + " ")], 1), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage'),
      "ripple": false
    },
    on: {
      "click": _vm.init
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-10"
  }), _c('v-dialog', {
    attrs: {
      "width": "1024",
      "persistent": ""
    },
    model: {
      value: _vm.dialogAddNewThemeTemplate,
      callback: function ($$v) {
        _vm.dialogAddNewThemeTemplate = $$v;
      },
      expression: "dialogAddNewThemeTemplate"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('addNewThemeTemplate')) + " ")]), _c('v-divider', {
    staticClass: "mx-4 mb-4"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('Theme', {
    ref: "formTheme",
    attrs: {
      "theme": {},
      "edit": false
    }
  })], 1), _c('v-divider', {
    staticClass: "mx-4"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.dialogAddNewThemeTemplate = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "ripple": false
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1)], 1), _vm.themes ? _c('v-card', [_c('v-card-title', [_c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "col-12 col-md-4"
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.themes,
      "search": _vm.search,
      "sort-by": "_id",
      "sort-desc": false,
      "loading": _vm.loading,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.enableRegistration`,
      fn: function (_ref) {
        var item = _ref.item;
        return [item.enableRegistration ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")])] : [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-times ")])]];
      }
    }, {
      key: `item.action`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-dialog', {
          attrs: {
            "width": "1024",
            "persistent": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "color": "primary",
                  "title": _vm.$t('edit'),
                  "outlined": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-pen ")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.dialogEditThemeTemplate[item._id],
            callback: function ($$v) {
              _vm.$set(_vm.dialogEditThemeTemplate, item._id, $$v);
            },
            expression: "dialogEditThemeTemplate[item._id]"
          }
        }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('modifyTheme')) + " - " + _vm._s(item._id) + " ")]), _c('v-divider', {
          staticClass: "mx-4 mb-4"
        }), _c('v-card-text', {
          staticClass: "pb-0"
        }, [_c('Theme', {
          ref: `formThemeEdit_${item._id}`,
          attrs: {
            "theme": item,
            "edit": true
          }
        })], 1), _c('v-divider', {
          staticClass: "mx-4"
        }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "color": "error",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              _vm.dialogEditThemeTemplate[item._id] = false;
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "color": "success",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              return _vm.saveEditTheme(item._id);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "persistent": "",
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "error",
                  "title": _vm.$t('delete'),
                  "outlined": "",
                  "disabled": item._id === 'DEFAULT'
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-trash ")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.dialogConfirmDeleteTheme[item._id],
            callback: function ($$v) {
              _vm.$set(_vm.dialogConfirmDeleteTheme, item._id, $$v);
            },
            expression: "dialogConfirmDeleteTheme[item._id]"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v(" " + _vm._s(_vm.$t('deleteThisTheme?')) + " ")]), _c('v-card-text', [_c('b', [_vm._v(_vm._s(_vm.$t('themeId')))]), _vm._v(" " + _vm._s(item._id))]), _c('v-card-text', [_c('b', [_vm._v(_vm._s(_vm.$t('themeTitle')))]), _vm._v(" " + _vm._s(item.name))]), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialogConfirmDeleteTheme[item._id] = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "error",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteTheme(item._id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('delete')) + " ")])], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }